$(function () {
    resizeIframe();
    resizeImg();

    $(window).on('resize', function () {
    	resizeIframe();
        resizeImg();
    });

    handleHoverEffect('.ff-zoom img', 2000);
    handleHoverEffect('.service-step1-video', 2000);

})

const resizeIframe = () => {

    $('.ff-zoom iframe').each(function () {

        $(this).removeAttr('style').removeAttr('width').removeAttr('height');
        if ($(this).parent().length) {
            $(this).unwrap();
        }
        $(this).wrap('<div class="responsive-iframe"></div>');
    });
    
}

const resizeImg = () => {
    $('.ff-zoom img').each(function () {
        $(this).css({
            'width': '100%',
            'height': 'auto'
        });       
    });
}


const handleHoverEffect = (selector, delay) => {
    var timer;

    $(selector).on('mouseenter', function () {
        var $this = $(this);
        timer = setTimeout(function () {
            $this.addClass('zoomed');
        }, delay);
    }).on('mouseleave', function () {
        clearTimeout(timer);
        $(this).removeClass('zoomed');
    });
}